/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { creatorText } from 'src/app/content-creator/creator.messages';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormGroup } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { SwitchroleService } from 'src/app/content-creator/services/header/switchrole.service';
import { TemplateService } from 'src/app/content-creator/services/templates/template.service';
import { ManyToOneRelationService } from 'src/app/content-creator/services/templates/many-to-one-relation/many-to-one-relation.service';
import { ManyToManyRelationService } from 'src/app/content-creator/services/templates/many-to-many-relation/many-to-many-relation.service';
import { UitiltyService } from 'src/app/content-creator/services/utilties/utilities.service';
import { DocParserRequest } from 'src/app/content-creator/models/utilities/docParser.request';
import { WebScrapperRequest } from 'src/app/content-creator/models/utilities/webScrapper.request';
import { SaveTemplateService } from 'src/app/content-creator/services/templates/save-template.service';
import { LoginService } from 'src/app/content-creator/services/login/login.service';
import { SwitchRoleRequest } from 'src/app/content-creator/models/Login/switch-role/switch-role-request';
import { SuccessErrorPopupComponent } from 'src/app/content-curator/components/common/success-error-popup/success-error-popup.component';
import { ContentTransLanguageRequest } from 'src/app/content-creator/models/Translation/content-translanguage-request';
import { TranslanguageService } from 'src/app/content-creator/services/translate/translanguage.service';
import { HeaderService } from 'src/app/content-creator/services/header/header.service';
import { SpinnerService } from 'src/app/content-creator/services/spinner/spinner.service';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { MenuService } from 'src/app/content-creator/services/menu/menu.service';
import { MasterListRequestResponse } from 'src/app/content-creator/models/template/many-to-one-relation/many-to-one-relation';
import { MtmContentTypeResponse } from 'src/app/content-creator/models/template/many-to-many-relation/many-to-many-relation.model';
import { ContentSaveResponse } from 'src/app/content-creator/models/template/content/content-save.response';
import { AkanaLoginResponse } from 'src/app/content-creator/models/Login/akana-login/akana-login-response';

export class SearchData {
  constructor(
    public id: string,
    public Path: string,
    public Status: string,
    public CreationDate: string,
    public ModificationDate: string,
    public CommonName: string
  ) {}
}

export class SearchDataOnlyone {
  constructor(
    public id: string,
    public AliasName: string,
    public Name: string,
    public FileName: string,
    public dialog: MatDialog
  ) {}
}

/** Component which holds Dialog box functionality*/
@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {
  @Input('form') layoutForm!: FormGroup;
  @Input('layout') layout = {};
  @Output() submitCommentEmitter = new EventEmitter<Event>();
  @Output() submitRenameEmitter = new EventEmitter<Event>();
  tableList = [];
  docParserRequest: DocParserRequest;
  webScrapperRequest: WebScrapperRequest;
  switchroleRequest: SwitchRoleRequest;
  dataSource = new MatTableDataSource(this.tableList);
  dialogBoxSubscription = new Subscription();
  urlSafe: SafeResourceUrl;
  searchdataSource!: MatTableDataSource<SearchDataOnlyone>;

  roleSwitcherText = creatorText.roleSwitcherText;
  roleSelection = creatorText.roleSelection;
  proceedText = creatorText.proceedText;
  cancelText = creatorText.cancelText;
  deleteFormText = creatorText.deleteFormText;
  deleteConfirmation = creatorText.deleteConfirmation;
  successText = creatorText.successText;
  successMessage = creatorText.successMessage;
  validationFailedText = creatorText.validationFailedText;
  errorMessage = creatorText.errorMessage;
  addText = creatorText.addText;
  createContentText = creatorText.createContentText;
  initiateTranslationText = creatorText.initiateTranslationText;
  translationLanguages = creatorText.translationLanguages;
  countryText = creatorText.countryText;
  cropText = creatorText.cropText;
  formFillCaution = creatorText.formFillCaution;
  process = creatorText.process;
  noContentsFoundText = creatorText.noContentsFoundText;
  processingText = creatorText.processingText;
  notesText = creatorText.notesText;
  commentBoxText = creatorText.commentBoxText;
  textText = creatorText.text;
  pathText = creatorText.pathText;
  titleText = creatorText.title;
  cropStageText = creatorText.cropStageText;
  renameText = creatorText.renameText;
  submitText = creatorText.submit;
  nameText = creatorText.Name;
  updateText = creatorText.update;
  warningText = creatorText.warningText;
  yesText = creatorText.yesText;
  noText = creatorText.noText;
  updateOnAllVariant = creatorText.updateOnAllVariant;
  selectAll = creatorText.selectAll;
  extendSessionText = creatorText.extendSessionText;
  selectAttributeVariantsText = creatorText.selectAttributeVariants;
  selectValuesCautionText = creatorText.selectValuesCautionText;
  logoutText = creatorText.logoutWarningHeadingText;
  editLinkText = creatorText.editLinkText;
  saveText = creatorText.saveText;
  userRole = '';
  languages = [];
  selectedValue = [];
  hideTable = true;
  displayColumns = ['Content Language', 'Country', 'Content Name'];
  roles = [];
  userDetails = {};
  tableOffset = 1;
  tableLastPage = 0;
  itemPerTable = 50;
  decodedSearchTableData = [];
  searchdisplayedColumns = [];
  variantdisplayedColumns = [];
  orderList = {};
  finalList = [];
  searchedList = '';
  selected = -1;
  shimmerType = ['search-box', 'dropdown', 'translation-popup-inProgress'];
  dropdownShimmer = true;
  variantClassName = '';
  isSearhBox = true;
  message = '';
  isCropChecked = false;
  isCropStageChecked = false;
  pageOffset = 0;
  utilityError = false;
  decodedForm;
  name = '';
  submitComment = '';
  submitRename = '';
  dataList: string[] = [];
  data = '';
  arr1 = [];
  tableMap = new Map();
  languageMap = new Map();
  isContent = false;
  errorArr = [];
  receivedData = [];
  text1 = '';
  utilityNoResults = false;
  viewMediaTable = [];
  showTable = false;
  countries = [];
  isCountry = [];
  selectedCountry = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transLanguage: any = {}; // It acts as array and object
  countryArr = [];
  countryCodeArr = [];
  contentTypeCodeArr = [];
  contentName = [];
  logoutWarningMessage = '';
  callButtonObj = {};
  apiSearchText = '';
  apiSearchTextMT1 = '';
  selectAllMTM = false;
  mt1MultiClassName: string[] = [];
  selectedMt1MultiClassName = '';

  /** Constructor which holds services and matdialog
   * @param {MatDialog}
   * @param {SessionStorageService}
   * @param {SwitchroleService}
   * @param {TemplateService}
   * @param {MatDialog}
   * @param {ManyToOneRelationService}
   * @param {MenuService}
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public activity,
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    public sessionStorageService: SessionStorageService,
    public templateService: TemplateService,
    public matDialog: MatDialog,
    public manyToOneRelationService: ManyToOneRelationService,
    public mantToManyRelationService: ManyToManyRelationService,
    public switchRoleService: SwitchroleService,
    private changeDetectorRefs: ChangeDetectorRef,
    public saveTemplateService: SaveTemplateService,
    private uitilityService: UitiltyService,
    public transLanguageService: TranslanguageService,
    public headerService: HeaderService,
    public spinnerService: SpinnerService,
    public _snackBar: MatSnackBar,
    public menuService: MenuService,
    public loginServiceProvider: LoginService,
    public sanitizer: DomSanitizer
  ) {
    this.docParserRequest = new DocParserRequest();
    this.webScrapperRequest = new WebScrapperRequest();
    this.switchroleRequest = new SwitchRoleRequest();
  }

  /** ngOnint lifecycle hook */
  ngOnInit(): void {
    this.userDetails = this.sessionStorageService.get('userDetails');
    this.roles = this.userDetails['roles'];
    if (this.userDetails['roles']?.length > 1) {
      this.userRole = this.userDetails['workflowConfigurations'][0].currentRole;
    } else if (this.userDetails['roles']?.length === 1) {
      this.userRole = this.userDetails['roles'][0].name;
    }
    this.userRole = this.roles.filter(
      (data) => data.name === this.userRole
    )[0].id;
    if (this.activity.type === 'searchBoxMTMSelection') {
      this.getMTMData();
    }
    if (this.activity.type === 'searchBoxOneSelection') {
      if (
        Array.isArray(this.activity.value.className) &&
        this.activity.value.className.length > 1
      ) {
        this.isSearhBox = false;
        this.mt1MultiClassName = this.activity.value.className;
      } else {
        this.mt1MultiClassName = [];
        this.getMT1Data(false);
      }
    }
    if (this.activity.type === 'variantsList') {
      this.getVariantsList();
    }
    // eslint-disable-next-line no-empty
    if (this.activity.type === 'utilityDialog') {
    }
    if (this.activity.type === 'viewMedia') {
      if (this.activity.value && this.activity.value.length > 0) {
        this.viewMediaTable = [...this.activity.value];
        this.searchdataSource = new MatTableDataSource(this.viewMediaTable);
        this.searchdisplayedColumns = Object.keys(this.viewMediaTable[0]);
        this.searchdisplayedColumns.push('Link', 'Delete');
        this.searchdisplayedColumns = this.searchdisplayedColumns.filter(
          (item) => item !== 'Checkbox'
        );
        this.changeDetectorRefs.detectChanges();
        this.layout['className'] = this.activity.className;
      } else {
        this.message = 'No Media as been added';
      }
      this.isSearhBox = false;
    }
    if (this.activity.type === 'translate') {
      this.receivedData = this.activity.description;
      this.getLanguage();
    }
    if (this.activity.type === 'callButton') {
      if (
        this.activity.description &&
        Object.keys(this.activity.description).length !== 0
      ) {
        this.callButtonObj = this.activity.description;
      }
    }

    if (this.activity.type === 'menuRename') {
      this.submitRename = this.activity.value.name;
    }
    if (this.activity.type === 'logout') {
      this.logoutWarningMessage = this.activity.value.message;
    }
    if (
      this.activity.type === 'Image' ||
      this.activity.type === 'Video' ||
      this.activity.type === 'Youtube'
    ) {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.activity.url
      );
    }
  }

  /** Function to handle previousPage */
  selectAllMTMField() {
    this.selectAllMTM = !this.selectAllMTM;
    if (this.searchdataSource.filteredData) {
      this.searchdataSource.filteredData.forEach((rowData) => {
        if (!this.selectAllMTM) {
          rowData['Checkbox'] = false;
        } else {
          rowData['Checkbox'] = true;
        }
        if (rowData['Checkbox']) {
          this.finalList.push(rowData);
        } else {
          this.finalList = this.finalList.filter(
            (data) => data.id !== rowData.id
          );
        }
      });
    }
  }

  /** Function to handle previousPage */
  previousPage() {
    if (this.tableOffset !== 0) {
      this.tableOffset--;
    }
    this.searchdataSource = new MatTableDataSource(
      this.decodedSearchTableData[this.tableOffset - 1]
    );
    this.changeDetectorRefs.detectChanges();
  }

  /** Function to handle updatePage */
  updatePage(event) {
    if (event.srcElement.value > this.tableLastPage)
      event.srcElement.value = this.tableLastPage;
    if (event.srcElement.value < 1) event.srcElement.value = 1;
    this.tableOffset = event.srcElement.value;
    this.getMT1Data(false);
  }

  /** Function to handle nextPage */
  nextPage() {
    this.tableOffset++;
    this.getMT1Data(false);
  }

  /** Function to handle updatePage */
  updatePageMTM(event) {
    if (event.srcElement.value > this.tableLastPage)
      event.srcElement.value = this.tableLastPage;
    if (event.srcElement.value < 1) event.srcElement.value = 1;
    this.tableOffset = event.srcElement.value;
    this.getMTMData();
  }

  /** Function to handle nextPage */
  nextPageMTM() {
    this.tableOffset++;
    this.getMTMData();
  }

  /** Function to handle switchrole */
  switchRole(id: string) {
    this.switchroleRequest.newRole = id;
    this.spinnerService.showOrhideSpinner(true);
    this.dialogBoxSubscription.add(
      this.loginServiceProvider.switchRole(this.switchroleRequest).subscribe(
        (data) => {
          if (data.Response.success) {
            if (this.userDetails['roles']?.length > 1) {
              this.userDetails['workflowConfigurations'][0].currentRole =
                this.roles.filter((data) => data.id === id)[0].name;
              this.userRole =
                this.userDetails['workflowConfigurations'][0].currentRole;
            } else if (this.userDetails['roles']?.length === 1) {
              this.userDetails['roles'][0].name = this.roles.filter(
                (data) => data.id === id
              )[0].name;
              this.userRole = this.userDetails['roles'][0].name;
            }
            this.sessionStorageService.set('userDetails', this.userDetails);
            this.switchRoleService.setUserRole(this.userRole);
            const errorObj = {
              message: data.Response.message,
              success: data.Response.success
            };
            this.spinnerService.showOrhideSpinner(false);

            this.matDialog.open(SuccessErrorPopupComponent, {
              data: errorObj,
              disableClose: true
            });
          } else {
            this.spinnerService.showOrhideSpinner(false);

            const errorObj = {
              message: data.Response.error,
              success: data.Response.success
            };
            this.matDialog.open(SuccessErrorPopupComponent, {
              data: errorObj,
              disableClose: true
            });
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (error) => {
          this.spinnerService.showOrhideSpinner(false);
        }
      )
    );
  }

  /** Function to handle showData */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectDataMTM(orderList, ob: MatCheckboxChange) {
    if (orderList.Checkbox) {
      this.finalList.push(orderList);
    } else {
      this.finalList = this.finalList.filter(
        (data) => data.id !== orderList.id
      );
    }
  }

  /** Function to handle displayData */
  displayData() {
    return;
  }

  /** Function to handle selectData */
  selectDataMT1(searchList, ob: MatCheckboxChange, checkbox) {
    if (!checkbox) {
      this.searchedList = '';
    }
    this.decodedSearchTableData.forEach((element) => {
      element.filter((data) => {
        if (data.id !== searchList.id) {
          data.Checkbox = false;
        }
      });
    });
    if (!checkbox) {
      this.searchedList = '';
    } else {
      this.searchedList = searchList;
    }
  }

  /** Function to handle selectedData */
  selectedData() {
    return;
  }

  /**
   * Function to handle filterTable
   * @param event event data
   */
  searchData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchdataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Function to handle filterTable
   * @param event event data
   */
  searchAPIData() {
    this.searchdataSource = new MatTableDataSource([]);
    this.decodedSearchTableData = [];
    this.tableOffset = 1;
    this.getMTMData(true);
  }

  /** Function to search api call */
  searchAPIDataMT1() {
    this.searchdataSource = new MatTableDataSource([]);
    this.decodedSearchTableData = [];
    this.tableOffset = 1;
    this.getMT1Data(true);
  }

  /** Function to get mt1 data */
  getMT1Data(searchParam, fromDropDown?) {
    this.isSearhBox = true;
    const params = {};
    this.message = '';
    if (searchParam) {
      this.activity.value.dependencyValue['key'] = this.apiSearchTextMT1;
    }
    if (this.apiSearchTextMT1 === '') {
      delete this.activity.value.dependencyValue['key'];
    }
    if (JSON.stringify(this.activity.value.dependencyValue) !== '{}') {
      params['search'] = `{"AND":${JSON.stringify(
        this.activity.value.dependencyValue
      )},"OR":{}}`;
    }
    params['start'] = (this.tableOffset - 1) * this.itemPerTable;
    params['pageSize'] = this.itemPerTable;
    if (
      Array.isArray(this.activity.value.className) &&
      this.activity.value.className.length > 1
    ) {
      params['className'] = this.selectedMt1MultiClassName;
    } else {
      params['className'] = this.activity.value.className;
    }

    if (
      !fromDropDown &&
      this.decodedSearchTableData.length >= this.tableOffset
    ) {
      this.searchdataSource = new MatTableDataSource(
        this.decodedSearchTableData[this.tableOffset - 1]
      );
      this.changeDetectorRefs.detectChanges();
    } else {
      this.isSearhBox = true;
      if (this.activity.value.apiType === 'master') {
        this.dialogBoxSubscription.add(
          this.manyToOneRelationService
            .getManyToOneSearchData(params)
            .subscribe((res) => {
              if (res.response.success) {
                const tempTableData = [];
                res.response.data.data.forEach((list) => {
                  if (
                    this.activity.value.className === 'Country' ||
                    this.activity.value.className === 'BayerLanguage'
                  ) {
                    tempTableData.push({
                      Checkbox: false,
                      Name: list.Name,
                      Key: list.key,
                      AliasName: list.AliasName,
                      id: list.id,
                      Path: list.path
                    });
                  } else {
                    tempTableData.push({
                      Checkbox: false,
                      Name: list.Name,
                      Key: list.key,
                      id: list.id,
                      Path: list.path
                    });
                  }
                });
                this.decodedSearchTableData[this.tableOffset - 1] =
                  tempTableData;
                this.tableLastPage = Math.ceil(
                  res.response.data.totalCount / this.itemPerTable
                );
                this.decodedSearchTableData[this.tableOffset - 1].sort(
                  this.sortTableData
                );
                this.searchdataSource = new MatTableDataSource(
                  this.decodedSearchTableData[this.tableOffset - 1]
                );
                this.searchdisplayedColumns = Object.keys(
                  this.decodedSearchTableData[this.tableOffset - 1][0]
                );
                this.changeDetectorRefs.detectChanges();
              } else {
                this.message = res.response.error;
              }

              this.isSearhBox = false;
            })
        );
      } else {
        this.dialogBoxSubscription.add(
          this.manyToOneRelationService
            .getManyToOneSearchDataContent(params)
            .subscribe((res: MasterListRequestResponse) => {
              if (res.response.success) {
                const tempTableData = [];
                res.response.data.data.forEach((list) => {
                  tempTableData.push({
                    Checkbox: false,
                    Name: list.Name,
                    Key: list.key,
                    id: list.id,
                    Path: list.path
                  });
                });
                this.decodedSearchTableData[this.tableOffset - 1] =
                  tempTableData;
                this.tableLastPage = Math.ceil(
                  res.response.data.totalCount / this.itemPerTable
                );
                this.decodedSearchTableData[this.tableOffset - 1].sort(
                  this.sortTableData
                );
                this.searchdataSource = new MatTableDataSource(
                  this.decodedSearchTableData[this.tableOffset - 1]
                );
                this.searchdisplayedColumns = Object.keys(
                  this.decodedSearchTableData[this.tableOffset - 1][0]
                );
                this.changeDetectorRefs.detectChanges();
              } else {
                this.message = res.response.error;
              }

              this.isSearhBox = false;
            })
        );
      }
    }
  }

  /** Function to get mtm data */
  getMTMData(searchParam?) {
    this.message = '';
    const params = {};
    const cpCropClassHardcodeArray = ['Disease', 'Weed', 'Insect'];
    const unpublishClassHardcodeArray = ['FragmentedContent'];
    if (searchParam) {
      this.activity.value.dependencyValue['key'] = this.apiSearchText;
    }
    if (this.apiSearchText === '') {
      delete this.activity.value.dependencyValue['key'];
    }

    if (JSON.stringify(this.activity.value.dependencyValue) !== '{}') {
      params['search'] = `{"AND":${JSON.stringify(
        this.activity.value.dependencyValue
      )},"OR":${JSON.stringify(this.activity.value.dependencyValueMTM)}}`;
    }
    params['start'] = (this.tableOffset - 1) * this.itemPerTable;
    params['pageSize'] = this.itemPerTable;
    if (this.activity.value?.isDABayerMedia) {
      this.itemPerTable = 100;
      params['pageSize'] = this.itemPerTable;
      params['start'] = (this.tableOffset - 1) * this.itemPerTable;
    } else {
      this.itemPerTable = 50;
    }
    params['className'] = this.activity.value.className;
    if (
      this.decodedSearchTableData.length >= this.tableOffset &&
      !searchParam
    ) {
      this.searchdataSource = new MatTableDataSource(
        this.decodedSearchTableData[this.tableOffset - 1]
      );
      this.changeDetectorRefs.detectChanges();
    } else {
      if (this.activity.value.apiType === 'contentType') {
        this.isSearhBox = true;
        this.dialogBoxSubscription.add(
          this.mantToManyRelationService
            .getManyToManySearchDataContentType(params)
            .subscribe((res: MtmContentTypeResponse) => {
              if (res['response'].success) {
                const tempTableData = [];
                let sortForCrop = false;
                res['response']['data']['data'].forEach((data) => {
                  // If Condition for CP Product - Crop sort
                  if (cpCropClassHardcodeArray.includes(params['className'])) {
                    tempTableData.push({
                      Checkbox: false,
                      Crop: data.Crop_MT1_Value,
                      Name: data.Name,
                      Key: data.key,
                      id: data.id,
                      Path: data.path
                    });
                    sortForCrop = true;
                  } else {
                    const pushItem = {
                      Checkbox: false,
                      Name: data.Name,
                      Key: data.key,
                      id: data.id,
                      Path: data.path
                    };
                    if (
                      unpublishClassHardcodeArray.includes(params['className'])
                    ) {
                      pushItem['Unpublish'] = data.published;
                    }
                    tempTableData.push(pushItem);
                  }
                });
                this.decodedSearchTableData[this.tableOffset - 1] =
                  tempTableData;
                this.tableLastPage = Math.ceil(
                  res['response']['data']['totalCount'] / this.itemPerTable
                );
                if (sortForCrop) {
                  this.decodedSearchTableData[this.tableOffset - 1].sort(
                    (a, b) => (a.Crop > b.Crop ? 1 : -1)
                  );
                } else {
                  this.decodedSearchTableData[this.tableOffset - 1].sort(
                    this.sortTableData
                  );
                }
                this.searchdataSource = new MatTableDataSource(
                  this.decodedSearchTableData[this.tableOffset - 1]
                );
                this.searchdisplayedColumns = Object.keys(
                  this.decodedSearchTableData[this.tableOffset - 1][0]
                );
                if (this.searchdisplayedColumns.indexOf('unpublish')) {
                  this.searchdisplayedColumns.splice(
                    this.searchdisplayedColumns.indexOf('unpublish'),
                    1
                  );
                }
                this.changeDetectorRefs.detectChanges();
              } else {
                this.message = res.response.error;
              }
              this.isSearhBox = false;
            })
        );
      } else {
        this.isSearhBox = true;
        this.dialogBoxSubscription.add(
          this.mantToManyRelationService
            .getManyToManySearchData(params)
            .subscribe((res: MasterListRequestResponse) => {
              if (res.response.success) {
                const tempTableData = [];
                res.response.data.data.forEach((data) => {
                  tempTableData.push({
                    Checkbox: '',
                    Name: data.Name,
                    Key: data.key,
                    id: data.id,
                    Path: data.path
                  });
                });
                this.decodedSearchTableData[this.tableOffset - 1] =
                  tempTableData;
                this.tableLastPage = Math.ceil(
                  res.response.data.totalCount / this.itemPerTable
                );
                this.decodedSearchTableData[this.tableOffset - 1].sort(
                  this.sortTableData
                );
                this.searchdataSource = new MatTableDataSource(
                  this.decodedSearchTableData[this.tableOffset - 1]
                );
                this.searchdisplayedColumns = Object.keys(
                  this.decodedSearchTableData[this.tableOffset - 1][0]
                );
                this.changeDetectorRefs.detectChanges();
              } else {
                this.message = res.response.error;
              }
              this.isSearhBox = false;
            })
        );
      }
    }
  }

  /** Function to process utility */
  utilityProcessing(type) {
    delete type.type;
    this.changeDetectorRefs.detectChanges();
    if (this.activity.value.utilityType === 'Document Praser') {
      delete this.activity.value.utilityType;
      this.docParserRequest.country =
        type.country === undefined
          ? this.showErrorMessage()
          : type.country.toString();
      if (this.isCropChecked) {
        if (this.activity.value.crop) {
          this.docParserRequest.crop = type.crop.toString();
        } else {
          this.showErrorMessage();
        }
      } else {
        this.utilityError = false;
        this.docParserRequest.crop = '';
      }
      if (this.isCropStageChecked) {
        this.docParserRequest.cropstage =
          type.cropstage === undefined
            ? this.showErrorMessage()
            : type.cropstage;
      } else {
        this.docParserRequest.cropstage = [];
      }
      // Getting Selected Template
      if (this.uitilityService.webScrapperData?.length > 0) {
        const templateTypesString = [];
        this.uitilityService.webScrapperData.forEach((element) => {
          templateTypesString.push(element.toString());
        });
        this.docParserRequest.templateTypes = templateTypesString;
      } else {
        this.showErrorMessage();
      }
      if (!this.utilityError) {
        this.dialogRef.close({
          event: 'close',
          type: 'docParser',
          data: this.docParserRequest
        });
      }
    } else {
      delete this.activity.value.type;
      delete this.activity.value.utilityType;

      this.webScrapperRequest = this.activity.value;
      if (this.isCropChecked) {
        if (this.activity.value.crop) {
          this.webScrapperRequest.crop = this.activity.value.crop.toString();
        } else {
          this.showErrorMessage();
        }
      } else {
        this.utilityError = false;
        this.webScrapperRequest.crop = '';
      }
      if (this.uitilityService.webScrapperData?.length > 0) {
        const contentTypeString = [];
        this.uitilityService.webScrapperData.forEach((element) => {
          contentTypeString.push(element.toString());
        });
        this.webScrapperRequest.contentType = contentTypeString;
      } else {
        this.showErrorMessage();
      }
      if (!this.utilityError) {
        this.dialogRef.close({
          event: 'close',
          type: 'WebScrapper',
          data: this.webScrapperRequest
        });
      }
    }
  }

  /** Function to show error message */
  showErrorMessage() {
    this.utilityError = true;
  }

  /** Function to get language data */
  getLanguage() {
    this.dropdownShimmer = true;
    const params: ContentTransLanguageRequest = {
      className: this.receivedData[1].split('_')[0],
      contentID: this.receivedData[0]
    };
    this.dialogBoxSubscription.add(
      this.transLanguageService
        .gettransLanguage(params)
        .subscribe((res: ContentTransLanguageRequest) => {
          if (res['success'] === true && res['response'].length !== 0) {
            this.transLanguage = res['response'];
            if (this.languages.length !== 0) {
              this.languages = [];
            }
            res['response'].forEach((ele) => {
              this.languages.push(ele.AliasName);
              this.languageMap.set(ele.AliasName, ele.id);
            });
            this.dropdownShimmer = false;
          } else {
            this.dropdownShimmer = false;
            this.languages = [];
          }
        })
    );
  }

  /** Function to create content */
  createContent() {
    this.text1 = 'Processing';
    this.arr1 = [];
    const languagecodeArr = [];
    const languageArr = [];
    const missing = [];
    for (const x of this.languageMap.values()) {
      languagecodeArr.push(x);
    }
    for (const z of this.languageMap.keys()) {
      languageArr.push(z);
    }
    for (let i = 0; i <= this.dataList.length - 1; i++) {
      const form = {
        key: this.tableMap.get(this.dataList[i]),
        className: this.receivedData[1].split('_')[0],
        ParentObjectForTranslation_MT1: this.receivedData[0],
        TranslationStatus: '1',
        TranslationLanguage: this.languageMap.get(this.dataList[i]),
        CreatorName: this.sessionStorageService.get('userDetails').userName,
        Name: this.tableMap
          .get(this.dataList[i])
          .split('_')
          .splice(-1)
          .join(''),
        BayerLanguage_MT1: this.languageMap.get(this.dataList[i]),
        Country_MT1: this.countryCodeArr[i]
      };
      if (this.activity.templateDetails.className === 'FragmentedContent') {
        form['ContentType_MT1'] = this.contentTypeCodeArr[i];
      }
      this.arr1.push(form);
    }
    this.arr1.forEach((ele, index) => {
      if (ele.Country_MT1 !== 'country') {
        this.dialogBoxSubscription.add(
          this.saveTemplateService
            .saveTemplate(ele)
            .subscribe((res: ContentSaveResponse) => {
              if (
                res['response'].success !== true ||
                ele.key.endsWith('undefined')
              ) {
                this.errorArr[index] = 'Error';
              } else {
                this.errorArr[index] = 'Success';
                for (const y of languagecodeArr) {
                  if (y === ele.TranslationLanguage) {
                    const index = languagecodeArr.indexOf(y);
                    missing.push(languageArr[index]);
                  }
                }
              }
              if (this.errorArr.length === this.arr1.length) {
                this.text1 = '';
                this.selectedValue = [];
                this.isContent = true;
                this.tableMap.clear();
                this.countryCodeArr = [];
                this.contentTypeCodeArr = [];
                this.contentName = [];
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                for (const i of missing) {
                  this.languages = this.languages.filter(
                    (item) => !missing.includes(item)
                  );
                }
              }
            })
        );
      } else {
        this.errorArr[index] = 'Error';
        if (this.errorArr.length === this.arr1.length) {
          this.text1 = '';
          this.selectedValue = [];
          this.isContent = true;
          this.tableMap.clear();
          this.countryCodeArr = [];
          this.contentTypeCodeArr = [];
          this.contentName = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const i of missing) {
            this.languages = this.languages.filter(
              (item) => !missing.includes(item)
            );
          }
        }
      }
    });
  }

  /** Function calls on close */
  onClose() {
    this.showTable = true;
    this.dataList.forEach((ele, i) => {
      if (this.transLanguage.response !== []) {
        this.transLanguage.forEach((element) => {
          if (element.AliasName === this.dataList[i]) {
            const tempArray = this.activity.templateDetails['name'].split('_');
            tempArray[0] = element.Name.toUpperCase();
            this.contentName[i] = tempArray.join('_');
          }
        });
        this.tableMap.set(this.dataList[i], this.contentName[i]);
      }
      this.getCountry(ele, i);
    });
  }

  /** Function calls on key up*/
  onKeyup(i) {
    this.tableMap.set(this.dataList[i], this.contentName[i]);
  }
  /** Function call on change */
  onChange() {
    this.errorArr = [];
    this.isContent = false;
    this.selectedValue.forEach((ele) => {
      if (!this.dataList.includes(ele)) {
        this.dataList.push(ele);
      }
    });
    this.dataList = this.dataList.filter((item) =>
      this.selectedValue.includes(item)
    );
  }

  /** Function to delete table data  */
  deleteTableData(index: number) {
    this.viewMediaTable.splice(index, 1);
    this.searchdataSource = new MatTableDataSource(this.viewMediaTable);
    this.changeDetectorRefs.detectChanges();
  }

  /** function to open in new tab */
  openInNewTab(id: number, name: string) {
    this.headerService.setMenuItemInfo({
      id: id,
      name: name,
      className: this.layout['className'],
      fromMenu: true
    });
  }

  /** Function to submit form */
  formSubmit() {
    this.message = 'Please add the comment to submit';
    if (this.submitComment !== '') {
      this.message = '';
      this.dialogRef.close({ event: 'close', data: this.submitComment });
    }
  }

  /** Function to submit on rename  */
  renameSubmit() {
    this.spinnerService.showOrhideSpinner(true);
    this.message = 'Please give valid name';
    if (this.submitRename !== '') {
      this.message = '';
      this.decodedForm['key'] = this.submitRename;
      this.decodedForm['className'] = this.activity.value.className;
      this.decodedForm['id'] = this.activity.value.id;
      if (this.activity.value.parent === 'variant-file') {
        this.decodedForm['parentId'] = this.activity.value.parentId;
      }
      this.saveTemplateService.saveTemplate(this.decodedForm).subscribe(
        (res: ContentSaveResponse) => {
          if (res['response'].success) {
            this.dialogRef.close({ event: 'close', data: this.submitRename });
            this.openSnackBar(
              { type: 'success', message: 'Renamed successfully!' },
              3000
            );
            this.menuService.setRenameAutoSaveEnabledSubject(this.decodedForm);
            this.spinnerService.showOrhideSpinner(false);
          } else {
            this.openSnackBar(
              {
                type: 'error',
                message: 'Error occured while renaming the Content!'
              },
              3000
            );
            this.dialogRef.close({ event: 'close', data: '' });
            this.spinnerService.showOrhideSpinner(false);
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (err) => {
          this.openSnackBar(
            {
              type: 'error',
              message: 'Error occured while renaming the Content!'
            },
            3000
          );
          this.dialogRef.close({ event: 'close', data: '' });
          this.spinnerService.showOrhideSpinner(false);
        }
      );
    }
  }

  /** Function call open snackbar */
  openSnackBar(data, duration: number) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: data,
      duration: duration
    });
  }

  /** Function to  call method*/
  method() {
    clearTimeout(timer1);
    var timer1 = setTimeout(() => {
      if (this.transLanguage.response === [] || this.languages.length === 0) {
        this.openSnackBar(
          { type: 'error', message: 'No translation language found!' },
          5000
        );
      }
    }, 3000);
  }

  /** Function to get location values */
  getCountry(language, i) {
    this.isCountry[i] = true;
    this.countries = [];
    let languageName;
    let langId;
    this.transLanguage.forEach((ele) => {
      if (ele.AliasName === language) {
        languageName = ele.Name;
        langId = ele.id;
      }
    });
    const params = {};
    params['className'] = 'Country';
    params[
      'search'
    ] = `{"AND":{"BayerLanguage_MTM_Value":"${languageName}","BayerLanguage_MT1":"${langId}"},"OR":{}}`;
    this.dialogBoxSubscription.add(
      this.manyToOneRelationService
        .getManyToOneSearchData(params, true)
        .subscribe((res: MasterListRequestResponse) => {
          if (res.response.success) {
            this.countries = res.response.data.data;

            this.isCountry[i] = false;
          } else {
            this.countries = [
              {
                id: 'country',
                key: 'No data found'
              }
            ];
            this.isCountry[i] = false;
          }
          this.countryArr[i] = this.countries;
        })
    );
  }

  /** Function to get content type values */
  getContentType(i) {
    let langId;
    this.transLanguage.forEach((ele) => {
      if (ele.AliasName === this.dataList[i]) {
        langId = ele.id;
      }
    });
    const params = {};
    params['className'] = 'ContentType';
    params['search'] = `{"AND":{"BayerLanguage_MT1":"${langId}"},"OR":{}}`;
    this.dialogBoxSubscription.add(
      this.manyToOneRelationService
        .getManyToOneSearchData(params, true)
        .subscribe((res: MasterListRequestResponse) => {
          if (res.response.success) {
            const contentType = res.response.data.data.filter((obj) => {
              return (
                this.activity.contentDetails['ContentType_MT1_Value'] ===
                obj.Name
              );
            });
            this.contentTypeCodeArr[i] = contentType[0]['id'];
          } else {
            this.countries = [
              {
                id: 'ContentType',
                key: 'No data found'
              }
            ];
          }
        })
    );
  }

  /** Function to select country  */
  selectCountry(i, event) {
    this.countryCodeArr[i] = event.value;
    if (this.activity.templateDetails.className === 'FragmentedContent') {
      this.getContentType(i);
    }
  }

  /** Function to  get variant list*/
  getVariantsList() {
    this.dataSource = this.activity.value.list;
    this.variantdisplayedColumns = this.activity.value.displayedColumns;
    this.variantClassName = this.activity.value.className;
  }

  /** Function to open varaint  */
  openVariantNewTab(id: number, name: string) {
    this.dialogRef.close({ event: 'close', type: 'variantsList' });
    this.headerService.setMenuItemInfo({
      id: id,
      name: name,
      className: this.variantClassName,
      fromMenu: true,
      isVarient: true,
      object_type: 'variant',
      parentId: this.activity.value.parentId
    });
  }

  /** Function to save data */
  save(text, path, title) {
    this.callButtonObj['text'] = text;
    this.callButtonObj['path'] = path;
    this.callButtonObj['title'] = title;
    this.dialogRef.close(this.callButtonObj);
  }

  /** Function to close dialogbox */
  close() {
    this.dialogRef.close(this.callButtonObj);
  }

  /** Function to sort */
  sortTableData(a, b) {
    const name1 = a.Name.toUpperCase();
    const name2 = b.Name.toUpperCase();
    let comparison = 0;
    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }

    return comparison;
  }

  /** Function to Extend the user session */
  extendSession() {
    /** Akana platform API - Bearer API */
    /** Added to reset the Bayer Token */
    this.spinnerService.showOrhideSpinner(true);
    this.dialogBoxSubscription.add(
      this.loginServiceProvider
        .getAkanaToken()
        .subscribe((resdata: AkanaLoginResponse) => {
          this.sessionStorageService.set('bearerToken', resdata['response']);
          this.sessionStorageService.set('UserLoginTime', new Date());
          this.dialogRef.close({
            event: 'close',
            type: 'logout',
            data: 'extended'
          });
          this.spinnerService.showOrhideSpinner(false);
          this.headerService.setTimerInfo(false);
        })
    );
  }

  /** Function to Cancel the extend session */
  cancelExtendSession() {
    this.headerService.setTimerInfo(true);
    this.dialogRef.close({
      event: 'close',
      type: 'logout',
      data: 'cancel extended'
    });
  }

  /**
   * Function to update SelectALL and DeselectALL
   *
   * @param {*} value SelectAll Value to update in List
   * @param {*} updateVariantList UpdateVariantList
   */
  selectAllUpdateToAllVariant(value, updateVariantList) {
    updateVariantList.updateVariantList.forEach((element) => {
      element.selected = value;
    });
  }

  /**
   * Function to Update the attributes for UpdatetoAllVariant
   *
   * @param {*} updateVariantList UpdateVariantList
   */
  UpdateToAllVariant(updateVariantList) {
    updateVariantList = updateVariantList.filter((value) => value.selected);
    const dialogRef = this.matDialog.open(DialogBoxComponent, {
      data: { type: 'updateToAllVariantWarning', value: '' },
      height: 'auto',
      width: '700px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value?.type === 'updateToAllVariantWarning') {
        if (value.data === true) {
          this.dialogRef.close({
            event: 'close',
            type: 'updateToAllVariant',
            data: true,
            value: updateVariantList
          });
        }
      }
    });
  }

  /**
   * Fucntion to Update Update to all Variants after the warning popup
   */
  UpdateToAllVariantWarning() {
    this.dialogRef.close({
      event: 'close',
      type: 'updateToAllVariantWarning',
      data: true
    });
  }

  getMT1DropValue() {
    this.tableOffset = 1;
    this.decodedSearchTableData = [];
    this.tableLastPage = 1;
    this.searchdataSource = new MatTableDataSource([]);
    this.getMT1Data(false, true);
  }
}
