import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { SessionStorageService } from 'angular-web-storage';

import { creatorText } from 'src/app/content-creator/creator.messages';
import { AkanaLoginResponse } from '../content-creator/models/Login/akana-login/akana-login-response';
import { ExternalLoginRequest } from '../content-creator/models/Login/external-login/external-login-request';
import { ExternalLoginResponse } from '../content-creator/models/Login/external-login/external-login-response';
import { InternalLoginRequest } from '../content-creator/models/Login/internal-login/internal-login-request';
import { InternalLoginResponse } from '../content-creator/models/Login/internal-login/internal-login-response';
import {
  UserDetailApiResponse,
  UserDetailResponse
} from '../content-creator/models/Login/user-details/user-details-response';
import { HeaderService } from '../content-creator/services/header/header.service';
import { SwitchroleService } from '../content-creator/services/header/switchrole.service';
import { LoginService } from '../content-creator/services/login/login.service';
import { SpinnerService } from '../content-creator/services/spinner/spinner.service';
import { SuccessErrorPopupComponent } from '../content-curator/components/common/success-error-popup/success-error-popup.component';
import { MasterListContentTrackerService } from '../content-curator/services/content-tracker/master-list-content-tracker.service';
import { ContentTrackerMasterList } from '../content-curator/models/services/content-tracker-models';

/** Component which holds Login functionality */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  externaloginRequest: ExternalLoginRequest;
  internaloginRequest: InternalLoginRequest;
  @ViewChild('loginForm', { static: true }) loginForm;

  agrowText = creatorText.agrowText;
  smartText = creatorText.smartText;
  agrowCaption = creatorText.agrowCaption;
  userNameValidation = creatorText.userNameValidation;
  userNameTouched = creatorText.userNameTouched;
  passwordValidation = creatorText.passwordValidation;
  passwordCharacterCheck = creatorText.passwordCharacterCheck;
  externalLoginButton = creatorText.externalLoginButton;
  internalLoginButton = creatorText.internalLoginButton;
  copyRightText = creatorText.copyRightText;
  bayerContentText = creatorText.bayerContentText;
  loginDisplay = false;

  /** Creates an instance of documenter
   * @param {loginServiceProvider}
   * @param {Router}
   * @param {SessionStorageService}
   */
  constructor(
    public loginServiceProvider: LoginService,
    private router: Router,
    public sessionStorageService: SessionStorageService,
    public spinnerService: SpinnerService,
    private authService: MsalService,
    public dialog: MatDialog,
    public masterlistservice: MasterListContentTrackerService,
    public switchRoleService: SwitchroleService,
    public headerService: HeaderService
  ) {
    this.externaloginRequest = new ExternalLoginRequest();
    this.internaloginRequest = new InternalLoginRequest();
  }

  /** ngOnInit lifecycle hook */
  ngOnInit() {
    this.sessionStorageService.clear();
  }

  /** Function that handles internalLogin */
  internalLogin() {
    this.spinnerService.showOrhideSpinner(true);
    this.internaloginRequest.cwid = this.loginForm.controls['u_name'].value;
    this.authService.loginPopup().subscribe({
      next: (result) => {
        this.internaloginRequest.cwid = result.account.username;
        // Akana platform API - Bearer API
        this.loginServiceProvider.getAkanaToken().subscribe(
          (resdata: AkanaLoginResponse) => {
            this.sessionStorageService.set('bearerToken', resdata['response']);
            this.headerService.setSessionInfo(false);
            this.sessionStorageService.set('UserLoginTime', new Date());
            // External API - username and password
            this.loginServiceProvider
              .internalLogin(this.internaloginRequest)
              .subscribe(
                (data: InternalLoginResponse) => {
                  if (data['response'].success) {
                    this.sessionStorageService.set(
                      'userToken',
                      data['response'].data
                    );
                    // Get userdetail API
                    this.loginServiceProvider.userDetail().subscribe(
                      (res: UserDetailApiResponse) => {
                        this.sessionStorageService.set(
                          'CropMandatory',
                          res.Response.data.Constants.dashboardCrop
                        );
                        this.sessionStorageService.set(
                          'Constants',
                          res.Response.data.Constants
                        );
                        const userDetails: UserDetailResponse =
                          res.Response.data;
                        if (userDetails.roles?.length > 1) {
                          userDetails['currentRole'] =
                            userDetails.workflowConfigurations[0].currentRole;
                        } else if (userDetails.roles?.length === 1) {
                          userDetails['currentRole'] =
                            userDetails.roles[0].name;
                        }

                        if (userDetails.workflowConfigurations.length === 0) {
                          this.sessionStorageService.set(
                            'userDetails',
                            userDetails
                          );
                          if (
                            userDetails.userName === 'ContentPortalAdmin' ||
                            userDetails.userName === 'admin'
                          ) {
                            this.router.navigate([
                              '/content-curator/dashboard'
                            ]);
                          } else {
                            this.router.navigate([
                              '/content-creator/panel/dashboard'
                            ]);
                          }
                        } else {
                          // master list
                          const arr = [
                            {
                              key: 'languages',
                              value: 'BayerLanguage'
                            },
                            {
                              key: 'countries',
                              value: 'Country'
                            },
                            {
                              key: 'crops',
                              value: 'Crop'
                            },
                            {
                              key: 'contentTypes',
                              value: 'ContentType'
                            }
                          ];
                          const queryParm1 = '{"AND":{},"OR":{}}';
                          const ids = [];
                          for (let k = 0; k < 4; k++) {
                            const key =
                              userDetails.workflowConfigurations[0][
                                arr[k]['key']
                              ];
                            if (key.length === 0) {
                              userDetails.workflowConfigurations[0][
                                arr[k]['value']
                              ] = [];
                              this.sessionStorageService.set(
                                'userDetails',
                                userDetails
                              );
                            } else {
                              if (key[0].id) {
                                for (let i = 0; i < key.length; i++) {
                                  ids.push(key[i]['id']);
                                }
                                userDetails.workflowConfigurations[0][
                                  arr[k]['value']
                                ] = ids;
                                this.sessionStorageService.set(
                                  'userDetails',
                                  userDetails
                                );
                                if (k === 0) {
                                  if (
                                    userDetails.userName ===
                                      'ContentPortalAdmin' ||
                                    userDetails.userName === 'admin'
                                  ) {
                                    this.router.navigate([
                                      '/content-curator/dashboard'
                                    ]);
                                  } else {
                                    this.router.navigate([
                                      '/content-creator/panel/dashboard'
                                    ]);
                                  }
                                }
                              } else {
                                this.masterlistservice
                                  .getMasterList(
                                    arr[k]['value'],
                                    queryParm1,
                                    '0',
                                    '25'
                                  )
                                  .subscribe((data: ContentTrackerMasterList) => {
                                    const res = data.response.data.data;
                                    for (let i = 0; i < key.length; i++) {
                                      for (let j = 0; j < res.length; j++) {
                                        if (
                                          key[i] === res[j]['AliasName'] ||
                                          key[i] === res[j]['key'] ||
                                          key[i] === res[j]['Name']
                                        ) {
                                          ids.push(res[j]['id']);
                                          break;
                                        }
                                      }
                                    }
                                    userDetails.workflowConfigurations[0][
                                      arr[k]['value']
                                    ] = ids;
                                    this.sessionStorageService.set(
                                      'userDetails',
                                      userDetails
                                    );
                                    if (k === 0) {
                                      if (
                                        userDetails.userName ===
                                          'ContentPortalAdmin' ||
                                        userDetails.userName === 'admin'
                                      ) {
                                        this.router.navigate([
                                          '/content-curator/dashboard'
                                        ]);
                                      } else {
                                        this.router.navigate([
                                          '/content-creator/panel/dashboard'
                                        ]);
                                      }
                                    }
                                  });
                              }
                            }
                          }
                        }

                        if (res.Response.data.roles.length > 1) {
                          this.switchRoleService.setUserRole(
                            res.Response.data.workflowConfigurations[0]
                              .currentRole
                          );
                        } else {
                          this.switchRoleService.setUserRole(
                            res.Response.data.roles[0].name
                          );
                        }
                      },
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      (error: Error) => {
                        this.spinnerService.showOrhideSpinner(false);
                      }
                    );
                  } else {
                    this.spinnerService.showOrhideSpinner(false);
                    let message = '';
                    message = 'CWID is not Valid';

                    const errorObj = {
                      message: message,
                      success: data['response'].success
                    };
                    this.dialog.open(SuccessErrorPopupComponent, {
                      data: errorObj,
                      disableClose: true
                    });
                  }
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                (error: Error) => {
                  this.spinnerService.showOrhideSpinner(false);
                }
              );
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (error: Error) => {
            this.spinnerService.showOrhideSpinner(false);
            const errorObj = {
              message: 'Please try again later',
              success: false
            };
            this.dialog.open(SuccessErrorPopupComponent, {
              data: errorObj,
              disableClose: true
            });
          }
        );
      },
      error: (error) => {
        this.dialog.open(SuccessErrorPopupComponent, {
          data: error,
          disableClose: true
        });
      }
    });
  }

  /** Function that handles externalLogin */
  externalLogin() {
    this.spinnerService.showOrhideSpinner(true);

    this.externaloginRequest.userName = this.loginForm.controls['u_name'].value;
    this.externaloginRequest.password = this.loginForm.controls['pass'].value;
    /** Akana platform API - Bearer API */
    this.loginServiceProvider.getAkanaToken().subscribe(
      (resdata: AkanaLoginResponse) => {
        this.sessionStorageService.set('bearerToken', resdata['response']);
        this.headerService.setSessionInfo(false);
        this.sessionStorageService.set('UserLoginTime', new Date());
        /** External API - username and password */
        if (
          this.externaloginRequest.userName !== '' &&
          this.externaloginRequest.password !== ''
        ) {
          this.loginServiceProvider
            .externalLogin(this.externaloginRequest)
            .subscribe(
              (data: ExternalLoginResponse) => {
                if (data['response'].success) {
                  this.sessionStorageService.set(
                    'userToken',
                    data['response'].data
                  );
                  /** Get userdetail API */
                  this.loginServiceProvider.userDetail().subscribe(
                    (res: UserDetailApiResponse) => {
                      this.sessionStorageService.set(
                        'CropMandatory',
                        res.Response.data.Constants.dashboardCrop
                      );
                      this.sessionStorageService.set(
                        'Constants',
                        res.Response.data.Constants
                      );
                      const userDetails = res.Response.data;
                      if (userDetails.roles?.length > 1) {
                        userDetails['currentRole'] =
                          userDetails.workflowConfigurations[0].currentRole;
                      } else if (userDetails.roles?.length === 1) {
                        userDetails['currentRole'] = userDetails.roles[0].name;
                      }

                      if (userDetails.workflowConfigurations.length === 0) {
                        this.sessionStorageService.set(
                          'userDetails',
                          userDetails
                        );
                        if (
                          userDetails.userName === 'ContentPortalAdmin' ||
                          userDetails.userName === 'admin'
                        ) {
                          this.router.navigate(['/content-curator/dashboard']);
                        } else {
                          this.router.navigate([
                            '/content-creator/panel/dashboard'
                          ]);
                        }
                      } else {
                        // master list
                        const arr = [
                          {
                            key: 'languages',
                            value: 'BayerLanguage'
                          },
                          {
                            key: 'countries',
                            value: 'Country'
                          },
                          {
                            key: 'crops',
                            value: 'Crop'
                          },
                          {
                            key: 'contentTypes',
                            value: 'ContentType'
                          }
                        ];
                        const queryParm1 = '{"AND":{},"OR":{}}';
                        let ids = [];
                        for (let k = 0; k < 4; k++) {
                          ids = [];
                          const key =
                            userDetails.workflowConfigurations[0][
                              arr[k]['key']
                            ];
                          if (key.length === 0) {
                            userDetails.workflowConfigurations[0][
                              arr[k]['value']
                            ] = [];
                            this.sessionStorageService.set(
                              'userDetails',
                              userDetails
                            );
                          } else {
                            if (key[0].id) {
                              for (let i = 0; i < key.length; i++) {
                                ids.push(key[i]['id']);
                              }
                              userDetails.workflowConfigurations[0][
                                arr[k]['value']
                              ] = ids;
                              this.sessionStorageService.set(
                                'userDetails',
                                userDetails
                              );
                              if (k === 0) {
                                if (
                                  userDetails.userName ===
                                    'ContentPortalAdmin' ||
                                  userDetails.userName === 'admin'
                                ) {
                                  this.router.navigate([
                                    '/content-curator/dashboard'
                                  ]);
                                } else {
                                  this.router.navigate([
                                    '/content-creator/panel/dashboard'
                                  ]);
                                }
                              }
                            } else {
                              this.masterlistservice
                                .getMasterList(
                                  arr[k]['value'],
                                  queryParm1,
                                  '0',
                                  '25'
                                )
                                .subscribe((data: ContentTrackerMasterList) => {
                                  const res = data.response.data.data;
                                  for (let i = 0; i < key.length; i++) {
                                    for (let j = 0; j < res.length; j++) {
                                      if (
                                        key[i] === res[j]['AliasName'] ||
                                        key[i] === res[j]['key'] ||
                                        key[i] === res[j]['Name']
                                      ) {
                                        ids.push(res[j]['id']);
                                        break;
                                      }
                                    }
                                  }
                                  userDetails.workflowConfigurations[0][
                                    arr[k]['value']
                                  ] = ids;
                                  this.sessionStorageService.set(
                                    'userDetails',
                                    userDetails
                                  );
                                  if (k === 0) {
                                    if (
                                      userDetails.userName ===
                                        'ContentPortalAdmin' ||
                                      userDetails.userName === 'admin'
                                    ) {
                                      this.router.navigate([
                                        '/content-curator/dashboard'
                                      ]);
                                    } else {
                                      this.router.navigate([
                                        '/content-creator/panel/dashboard'
                                      ]);
                                    }
                                  }
                                });
                            }
                          }
                        }
                      }

                      if (res.Response.data.roles.length > 1) {
                        this.switchRoleService.setUserRole(
                          res.Response.data.workflowConfigurations[0]
                            .currentRole
                        );
                      } else {
                        this.switchRoleService.setUserRole(
                          res.Response.data.roles[0].name
                        );
                      }
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    (error: Error) => {
                      this.spinnerService.showOrhideSpinner(false);
                    }
                  );
                } else {
                  this.spinnerService.showOrhideSpinner(false);
                  let message = '';
                  message = 'Enter valid Username and Password';

                  const errorObj = {
                    message: message,
                    success: data['response'].success
                  };
                  this.dialog.open(SuccessErrorPopupComponent, {
                    data: errorObj,
                    disableClose: true
                  });
                }
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              (error: Error) => {
                this.spinnerService.showOrhideSpinner(false);
              }
            );
        } else {
          this.spinnerService.showOrhideSpinner(false);
          const errorObj = {
            message: 'Enter Username and Password',
            success: false
          };
          this.dialog.open(SuccessErrorPopupComponent, {
            data: errorObj,
            disableClose: true
          });
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (error: Error) => {
        this.spinnerService.showOrhideSpinner(false);
        const errorObj = {
          message: 'Please try again later',
          success: false
        };
        this.dialog.open(SuccessErrorPopupComponent, {
          data: errorObj,
          disableClose: true
        });
      }
    );
  }
}
